import logo from './logo.svg';
import {useContext, useEffect, useState, useMemo} from 'react';
import axios from 'axios';
import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/home/home";
import Commands from "./components/commands/commands";
import NavBar from './components/navbar/navbar'
import { UserContext } from './context/user_context';
import Dashboard from './components/dashboard/dashboard';


function App() {
  const [user, setUser] = useState(null);

  const getCurrentUser = async () => {
    let response = await axios.get('/auth/current-session');
    if(response.data.user) {
      console.log(`Got user in App.js:`)
      console.log(response.data.user)
      setUser(response.data.user)
    }
  }

  useEffect(() => {
    console.log('Im here')
    getCurrentUser()
  }, [])

  const userContextValue = useMemo(
    () => (
      user && {
        twitch_user_id: user.twitch_user_id,
        login: user.login,
        display_name: user.display_name,
        type: user.type,
        broadcaster_type: user.broadcaster_type,
        accessToken: user.accessToken,
        authStrategy: user.authStrategy
      }
    ), [user])

  return (
    <div className="App">
      <UserContext.Provider value={userContextValue}>
        <NavBar/>
          <Routes style={{flex: 1}}>
            <Route path="/" element={<Home  />} />
            <Route path="/dashboard" element={<Dashboard  />} />
            <Route path="/commands" element={<Commands  />} />
            <Route path='*' exact={true} element={<Navigate to='/' />} />
          </Routes>
      </UserContext.Provider>
    </div>
  );
}

export default App;
