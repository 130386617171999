import {React, useEffect, useContext, useState} from 'react';
import { Container, Table, Button, Spinner, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { UserContext } from '../../context/user_context';
import axios from 'axios';
import './commands.style.css'

const Commands = () => {
  const contextUser = useContext(UserContext)
  const [commands, setCommands] = useState([])
  const [commandInput, setCommandInput] = useState(null)
  const [commandValueInput, setCommandValueInput] = useState(null)
  const [modOnlyInput, setModOnlyInput] = useState(false)
  const [isActiveInput, setIsActiveInput] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
      fetchUserCommands()
  }, [contextUser])

  const fetchUserCommands = async () => {
    if(!contextUser) return;
    let userCommands = await axios.get(`/api/commands/${contextUser.twitch_user_id}`)
    console.log(`GOT RESPONSE FROM FETCH COMMANDS:`)
    if(userCommands.status == 200 && userCommands.data) {
      console.log(userCommands.data)
      setCommands(userCommands.data)
      setIsLoading(false)
    } 
  }

  const addCommand = async () => {
    console.log(`Adding command: ${commandInput} -> ${commandValueInput} isMod: ${modOnlyInput} isActive: ${isActiveInput}`)
    let commandToAdd = await axios.post(`/api/commands`,
      {
        twitch_user_id: contextUser.twitch_user_id,
        command: commandInput,
        value: commandValueInput,
        modOnly: modOnlyInput,
        isActive: isActiveInput
      },{
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
    if(commandToAdd.status == 201) {
      setIsLoading(true)
      await fetchUserCommands()
    } 
  }
  
  const handleChangeCommand = async (event) => {
    setCommandInput(event.target.value)
  }

  const handleChangeCommandValue = async (event) => {
    setCommandValueInput(event.target.value)
  }

  const handleChangeModOnly = async (event) => {
    setModOnlyInput(event.target.checked)
  }

  const handleChangeIsActive = async (event) => {
    setIsActiveInput(event.target.checked)
  }
  
  const editCommand = async (command) => {

  }

  const deleteCommand = async (command) => {
    let commandToDelete = await axios.delete(`/api/commands/${command._id}`)
    console.log(commandToDelete)
  }
  

  return (
    <div className="commands-view">
      <Container className='h-100'>
      {isLoading &&
        <Row className="h-50" style={{justifyContent: 'center'}}>
          <Col className="mx-auto d-flex justify-content-center" xs={6}>
            <Spinner animation="border" size="lg"></Spinner>
          </Col>
        </Row>
       
      }
      {!isLoading &&
        <>
        <h2>Commands</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Command</th>
              <th>Value</th>
              <th>Mod Only</th>
              <th>Active</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              commands.map((c, index) => {
                console.log(c)
                return (
                  <tr key={`${index}${c.command_key}`}>
                    <td>{c.command_key}</td>
                    <td>{c.command_value}</td>
                    <td>{`${c.is_mod_only}`}</td>
                    <td>{`${c.is_active}`}</td>
                    <td>
                      <Row>
                        {/* <Col className="d-flex justify-content-center" xs={6}>
                          <FontAwesomeIcon className="mx-auto my-auto" style={{fontSize: '30px'}}
                            icon={faPen} onClick={async () => { editCommand(c) }}/>
                        
                        </Col> */}
                        <Col className="d-flex justify-content-center" xs={12}>
                          <FontAwesomeIcon className="mx-auto my-auto" style={{fontSize: '30px'}}
                            icon={faTrash} onClick={async () => { deleteCommand(c) }}/>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                )
              })
            }
            <tr>
              <td>
                <Form.Control type="text" placeholder="Command" onChange={handleChangeCommand}/>
              </td>
              <td>
                <Form.Control type="text" placeholder="Value" onChange={handleChangeCommandValue}/>
              </td>
              <td>
                <Form.Check 
                  type="switch"
                  id="mod-only-switch"
                  checked={modOnlyInput}
                  onChange={handleChangeModOnly}
                  label="Mod Only?"
                />
              </td>
              <td>
                <Form.Check 
                  type="switch"
                  id="is-active-switch"
                  label="Active?"
                  checked={isActiveInput}
                  onChange={handleChangeIsActive}
                />
              </td>
              <td></td>
            </tr>
          </tbody>
        </Table>
        <Button className='w-auto' variant="success" onClick={async () => { addCommand() }}>
          Add Command
        </Button>
        </>
      }
      </Container>
    </div>
  )
}

export default Commands;