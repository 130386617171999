import {React, useEffect, useContext, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Container, Card, Button } from 'react-bootstrap';
import { UserContext } from '../../context/user_context';
import axios from 'axios';
import './dashboard.style.css'

const Dashboard = () => {
    const contextUser = useContext(UserContext)    
    const [userStats, setUserStats] = useState(null)
    const [userIsActivated, setUserIsActivated] = useState(false)
    useEffect(() => {
        console.log(`in dashbaord useEffect: ${contextUser}`)
        fetchUserStats()
    }, [contextUser])

    const fetchUserStats = async () => {
        if(!contextUser) return;
        let userStats = await axios.get(`/api/dashboard/stats/${contextUser.twitch_user_id}`)
        console.log(userStats.data)
        setUserStats(userStats.data)
        setUserIsActivated(userStats.data.userData.is_activated)
    }
    
    const activateAccount = async () => {
        if(!contextUser) return;
        let activateAccount = await axios.post(`/api/activate_integration_user`, { twitch_user_id: contextUser.twitch_user_id })

        fetchUserStats()
    }

    return (
        <div className="dashboard-view">
            <Container className='h-100 mt-4'>
                {userStats &&
                <Col>
                    <Row className='mb-5'>
                        {
                            !userIsActivated &&
                            <Container>
                                <h3>Uh oh. It looks like you haven't turned on SlugBot on your twitch account.</h3>
                                <Button className='w-auto' variant="success" onClick={async () => { activateAccount() }}>
                                    Activate The Slug
                                </Button>
                            </Container>
                        }
                    </Row>
                    <Row className='mt-2'>
                        <Card style={{ width: '18rem' }}>
                            <div className="card-offset-icon">
                                <FontAwesomeIcon className="mx-auto my-auto" style={{fontSize: '30px'}} icon={faUser}/>
                            </div>
                            <Card.Body>
                                <Card.Title>Total RPG Users</Card.Title>
                                <Card.Text>
                                {`${userStats.rpgUserCount}`}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
                
                }
            </Container>
        </div>
    )
}

export default Dashboard;