import {React, Component, useContext} from 'react';
import { Row, Col, Container, Nav, Button } from 'react-bootstrap';
import logo from '../../assets/Slugbot3.png';
import './home.style.css'
import HomeInfoCard from '../home-info-card/home-info-card'

const Home = () => {
  return (
    <div className="home-view">
      <Container className='h-100'>
        <Row className='h-100'>
           <HomeInfoCard/>
          <Col>
            <img style={{maxWidth:"100%", maxHeight:"100%", marginTop: "20%"}}src={logo} alt="sluglogo"/>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Home;