import {React, Component, useContext, useEffect, useState} from 'react';
import logo from '../../assets/Slugbot3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../App.css';
import { UserContext } from '../../context/user_context';
import './navbar.style.css'

const NavBar = () => {
  const contextUser = useContext(UserContext)
  const [user, setUser]  = useState(null)
  useEffect(() => {
    setUser(contextUser);
  }, [contextUser]);
  return (
    <Navbar className="navbar-parent" style={{backgroundColor: '#dcdde1'}}>
        <Navbar.Brand href="/">
          <div style={{width:"200px"}}>
          <img style={{maxWidth:"100%", maxHeight:"100%"}}src={logo} alt="sluglogo"/>
          </div>
        
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <div className='w-100 d-flex flex-row'>
          <Navbar.Collapse id="basic-navbar-nav">
            { !user && <Nav className="me-auto">
                <Nav.Link as={Link} to={{pathname:'/'}}>
                  Home
                </Nav.Link>
              </Nav>
            }
            { user && <Nav className="me-auto">
                <Nav.Link as={Link} to={{pathname:'/dashboard'}}>
                  Dashboard
                </Nav.Link>
                <Nav.Link as={Link} to={{pathname:'/commands'}}>
                  Commands
                </Nav.Link>
              </Nav>
            }
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            { user &&
              <>
                <Navbar.Text>
                  {`Welcome, ${user.display_name}!`}
                </Navbar.Text>
                <Button style={{marginLeft: '20px'}} type="button" className="btn btn-twitch" href={"/auth/logout"}>
                  Logout
                </Button>
              </>
            }
            { !user &&
              <Button type="button" className="btn btn-twitch" href={"/auth/login"}>
                <FontAwesomeIcon icon={faTwitch} /> Login With Twitch
              </Button>
            }
          </Navbar.Collapse>
        </div>
    </Navbar>
  )
}

export default NavBar;