import {React, Component, useContext} from 'react';
import { Row, Col, Container, Nav, Button } from 'react-bootstrap';

import './home-info-card.style.css'

const HomeInfoCard = () => {
    
  return (
    <Col className="home-info-card">
        <Row className='sales-title-container' style={{flex: 1}}>
            <span className='sales-title'>Enhance your Twitch chat experience.</span>
        </Row>
        <Row className='sales-subtitle-container' style={{flex: 1}}>
            <span className='sales-subtitle'>
                Add custom commands, embed Twitch API capabilities, and explore the expansive possibilities of ChatRPG.
            </span>
        </Row>
        <Row className='action-button-container' style={{flex: 2}}>
            <Col>
                <Button type="button" className="btn">
                    Get Started
                </Button>
            </Col>
            <Col>
                <Button type="button" className="btn">
                    Learn More
                </Button>
            </Col>
            
            
        </Row>
    </Col>
  )
}

export default HomeInfoCard;