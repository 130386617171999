import { createContext } from 'react';

export const UserContext = createContext({
    twitch_user_id: null,
    login: null,
    display_name: null,
    type: null,
    broadcaster_type: null,
    accessToken: null,
    authStrategy: null
})